import { AuthProvider as ap } from "react-oidc-context"
import { WebStorageStateStore } from "oidc-client-ts"
import { OidcConfig } from "./ConstantService"

function AuthProvider(props) {
  const AdditionOidcConfig = {
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  }
  return ap({ ...props, ...OidcConfig, ...AdditionOidcConfig })
}

export default AuthProvider
