export const ApiConfig = {
  endpoint: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
}

export const OidcConfig = {
  authority: process.env.REACT_APP_COGNITO_USERPOOL_ENDPOINT,
  client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_COGNITO_CALLBACK_URL,
};

export const CheckType = {
  CAMPAIGN: "Campaign",
  ADGROUP: "AdGroup",
};

export const RuleType = {
  GEO_TARGET: "Geo targeting applied (within Australia)",
  DISPLAY_BRAND_SAFETY: "Display brand safety enabled (IAS)",
  VIDEO_BRAND_SAFETY: "Video brand safety enabled (IAS)",
  DISPLAY_FRAUD_PREVENTION: "Display fraud prevention enabled (IAS)",
  VIDEO_FRAUD_PREVENTION: "Video fraud prevention enabled (IAS)",
  EXCLUDE_SITELIST: 'Sitelist exclusion applied ("Matterkind AU Master Block List")',
  EXCLUDE_CATEGORY: 'Custom category exclusions ("integral-1101640, integral-1016654, integral-1513949, integral-1000001")',
  PRIVATE_CONTRACT: "Private market only",
  CAMPAIGN_TIMEZONE: "Campaign timezone (set to an AU timezone)",
};

export const ChannelType = {
  VIDEO_CTV: "Video: CTV",
  VIDEO_OLV: "Video: OLV",
  DISPLAY: "Display",
  AUDIO: "Audio",
  HIGH_IMPACT: "High Impact",
};

export const RuleMatrix = {
  VIDEO_CTV: [
    RuleType.GEO_TARGET,
    RuleType.PRIVATE_CONTRACT,
    RuleType.CAMPAIGN_TIMEZONE,
  ],
  VIDEO_OLV: [
    RuleType.GEO_TARGET,
    RuleType.VIDEO_BRAND_SAFETY,
    RuleType.VIDEO_FRAUD_PREVENTION,
    RuleType.EXCLUDE_SITELIST,
    RuleType.EXCLUDE_CATEGORY,
    RuleType.CAMPAIGN_TIMEZONE,
  ],
  DISPLAY: [
    RuleType.GEO_TARGET,
    RuleType.DISPLAY_BRAND_SAFETY,
    RuleType.DISPLAY_FRAUD_PREVENTION,
    RuleType.EXCLUDE_SITELIST,
    RuleType.EXCLUDE_CATEGORY,
    RuleType.CAMPAIGN_TIMEZONE,
  ],
  AUDIO: [
    RuleType.GEO_TARGET,
    RuleType.EXCLUDE_SITELIST,
    RuleType.PRIVATE_CONTRACT,
    RuleType.CAMPAIGN_TIMEZONE,
  ],
  HIGH_IMPACT: [
    RuleType.GEO_TARGET,
    RuleType.EXCLUDE_SITELIST,
    RuleType.PRIVATE_CONTRACT,
    RuleType.CAMPAIGN_TIMEZONE,
  ],
};
