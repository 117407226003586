import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, hasAuthParams } from "react-oidc-context";
import Navigation from "./Navigation"

function MainLayout(props) {
  const auth = useAuth();
  const { children } = props;
  const navigate = useNavigate();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
    return auth.events.addUserLoaded(() => navigate("/"))
  }, [auth, hasTriedSignin, navigate]);

  return (
    <>
      <header>
        {auth.isAuthenticated ? <Navigation User={auth?.user.profile} /> : ""}
      </header>
      <main className="container-fluid p-b-20">
        {auth.isAuthenticated ? children : <div>Redirecting to TTD Desk for signing in ...</div>}
      </main>
    </>
  )
}

export default MainLayout;
