import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { Check } from "../services/ApiService";
import { RuleMatrix, RuleType, ChannelType, CheckType } from '../services/ConstantService';


function TypeSelecter({ onChange }) {
  let options = Object.entries(CheckType).map(([k, v]) => <option value={k} key={k}>{v}</option>)
  return (
    <Form.Select name='Type' onChange={onChange}>
      {options}
    </Form.Select>
  )
}

function ChannelSelecter({ onChange }) {
  let options = Object.entries(ChannelType).map(([k, v]) => <option value={k} key={k}>{v}</option>)
  return (
    <Form.Select name='Type' onChange={onChange}>
      {options}
    </Form.Select>
  )
}

function CampaignInput({ value, onChange }) {
  return (
    <Form.Control type="text" name='Campaign'
      onChange={onChange} value={value} placeholder="Input a campaign id" />
  )
}

function AdgroupInput({ value, onChange }) {
  return (
    <Form.Control as="textarea" name='Adgroups' rows={5}
      onChange={onChange} value={value.join("\r\n")} placeholder="Input adgroup ids" />
  )
}

function SubmitButton({ value, onClick }) {
  let text = value.is_processing ? " Processing..." : "Submit"
  let disabled = value.is_processing
  return (
    <Button variant="primary" disabled={disabled} onClick={onClick}>
      <Spinner as="span" animation="border" size="sm"
        role="status" aria-hidden="true" hidden={!value.is_processing} />
      {text}
    </Button>
  )
}

function RuleMatrixView({ value }) {
  let channel_key = value
  let ths = Object.entries(ChannelType).map(([ck, cv]) => <th key={ck}>{cv}</th>)
  let rows = Object.entries(RuleType).map(([rk, rv]) => {
    let items = Object.entries(ChannelType).map(([ck, cv]) => {
      let item_value = RuleMatrix[ck].includes(rv) ? "X" : ""
      return <td key={ck}>{item_value}</td>
    })
    return (
      <tr key={rk}>
        <td style={{ backgroundColor: RuleMatrix[channel_key].includes(rv) ? "LightGrey" : "" }} >{rv}</td>
        {items}
      </tr>
    )
  })
  return (
    <Table>
      <thead>
        <tr>
          <th>Rule</th>
          {ths}
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  )
}

function CheckRuleResultView({ value }) {
  return (
    <>
      <td>{RuleType[value.name]}</td>
      <td>{value.message}</td>
    </>
  )
}

function CheckAdgroupResultView({ value }) {
  const results = value.results.filter((result) => !result.flag)
  const items = results.map((v, i) => <tr key={i}><CheckRuleResultView value={v} /></tr>)
  return (
    <>
      <td><a href={`https://desk.thetradedesk.com/app/advertiser/${value.advertiser_id}/buy/adgroup/${value.adgroup_id}`}>{value.adgroup_id}</a></td>
      <td>

        <Table>
          <tbody>
            {items.length ? items : "No errors found for this AdGroup"}
          </tbody>
        </Table>
      </td>
    </>
  )
}

function CheckErrorView({ value }) {
  let msg = value.error
  if (msg.startsWith("Failed to fetch") || msg.startsWith("NetworkError when attempting to fetch resource")) {
    msg = "Login expired. Please refresh the page and submit again."
  }
  if (msg.startsWith("Failed to get campaign adgroups")) {
    let parts = /^Failed to get campaign adgroups for (.*?)\./.exec(msg)
    let id = parts ? parts[1] : ""
    msg = `Incorrect Campaign ID "${id}". Please fix it and submit again.`
  }
  if (msg.startsWith("Failed to get adgroup")) {
    let parts = /^Failed to get adgroup with id (.*?)\./.exec(msg)
    let id = parts ? parts[1] : ""
    msg = `Incorrect AdGroup ID "${id}". Please fix it and submit again.`
  }
  return (
    <>
      <p>Got the following error from server. Please double check your input, refresh the page, and submit again. If it still fails, please contact administrator.</p>
      <p style={{ color: 'red' }}>{msg}</p>
    </>
  )
}

function CheckResponseView({ value }) {
  if (value.error) {
    return <CheckErrorView value={value} />
  }

  const items = value.results.map((v, i) => <tr key={i}><CheckAdgroupResultView value={v} /></tr>)
  return (
    items.length ?
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Adgroup</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          {items}
        </tbody>
      </Table> :
      <></>
  )
}

function AdConfigCheck() {
  const auth = useAuth();
  const [input, setInput] = useState({
    type: "CAMPAIGN",
    campaign_id: "",
    adgroup_ids: [],
    channel: "VIDEO_CTV",
  });
  const [submitState, setSubmitState] = useState({
    is_processing: false,
  });
  const [checkResponse, setCheckResponse] = useState({
    type: "CAMPAIGN",
    error: null,
    results: [],
  });

  function handleTypeChange(event) {
    setInput({ ...input, ...{ type: event.target.value } })
  }

  function handleChannelChange(event) {
    setInput({ ...input, ...{ channel: event.target.value } })
  }

  function handleCampaignChange(event) {
    let id = event.target.value
    setInput({ ...input, ...{ campaign_id: id } })
  }

  function handleAdgroupChange(event) {
    let ids = event.target.value.split(/[\s,;]+/)
    setInput({ ...input, ...{ adgroup_ids: ids } })
  }

  async function handleSubmit(event) {
    try {
      setSubmitState({
        is_processing: true
      })
      let ret = await Check(auth.user.id_token, input)
      setCheckResponse({
        type: input.type,
        results: ret.results,
      })
    } catch (err) {
      setCheckResponse({
        type: input.type,
        error: err.message
      })
    }
    finally {
      setSubmitState({
        is_processing: false
      })
    }
  }

  return (
    <div>
      <h1>Check Campaign or AdGroups</h1>
      <Container fluid>
        <Row>
          <Col lg={3}>
            <br />
            <p><TypeSelecter onChange={handleTypeChange} /></p>
            <p><ChannelSelecter onChange={handleChannelChange} /></p>
            {
              input.type === "CAMPAIGN" ?
                <p><CampaignInput value={input.campaign_id} onChange={handleCampaignChange} /></p> :
                <p><AdgroupInput value={input.adgroup_ids} onChange={handleAdgroupChange} /></p>
            }
            <p><SubmitButton value={submitState} onClick={handleSubmit} /></p>
          </Col>
          <Col lg={1} />
          <Col lg={8}>
            <div><RuleMatrixView value={input.channel} /></div>
          </Col>
        </Row>
      </Container>
      <hr />
      <div><CheckResponseView value={checkResponse} /></div>
    </div>
  )
}

export default AdConfigCheck