import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';

import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { GetUsers, SetUsers } from "../services/ApiService";

function UsersInput({ value, onChange }) {
  return (
    <Form.Control as="textarea" name='Users' rows={10}
      onChange={onChange} value={value.join("\r\n")} placeholder="Input user emails" />
  )
}

function SubmitButton({ value, onClick }) {
  let text = value.is_processing ? " Processing..." : "Submit"
  let disabled = value.is_processing
  return (
    <Button variant="primary" disabled={disabled} onClick={onClick}>
      <Spinner as="span" animation="border" size="sm"
        role="status" aria-hidden="true" hidden={!value.is_processing} />
      {text}
    </Button>
  )
}

function ManageUsers({ is_admin }) {
  const auth = useAuth();
  const [hasPermission, setHasPermission] = useState(false)
  const [userlist, setUserlist] = useState([])
  const [resultMessage, setResultMessage] = useState("")
  const [submitState, setSubmitState] = useState({
    is_processing: false,
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let users = await GetUsers(auth.user.id_token, is_admin)
        setHasPermission(true)
        setUserlist(users)
      } catch (err) {
        setResultMessage(err.message)
      }
    };
    fetchUsers();
  }, [auth.user.id_token, is_admin]);

  function handleUsersChange(event) {
    let users = event.target.value.split(/[\s,;]+/)
    setUserlist(users)
  }

  async function handleSubmit(event) {
    try {
      setSubmitState({
        is_processing: true
      })
      let users = await SetUsers(auth.user.id_token, userlist, is_admin)
      setUserlist(users)
      setResultMessage("Users are updated successfully.")
    } catch (err) {
      setResultMessage(err.message)
    }
    finally {
      setSubmitState({
        is_processing: false
      })
    }
  }

  return hasPermission ? (
    <div>
      <h1>{is_admin ? "Manage Admin Users" : "Manage Users"}</h1>
      <p><UsersInput value={userlist} onChange={handleUsersChange} /></p>
      <p><SubmitButton value={submitState} onClick={handleSubmit} /></p>
      <p style={{ color: 'red' }}> {resultMessage}</p>
    </div>
  ) : (
    is_admin ? <p></p> : <p style={{ color: 'red' }}> {resultMessage}</p>
  )
}

function Users() {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={6}>
            <ManageUsers is_admin={false} />
          </Col>
          <Col lg={6}>
            <ManageUsers is_admin={true} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Users