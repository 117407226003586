import { ApiConfig } from "./ConstantService"

export async function Check(token, request) {
  let url_path = request.type === "CAMPAIGN" ? "/check_campaign" : "/check_adgroups"
  const resp = await fetch(
    ApiConfig.endpoint + url_path, {
    method: "POST",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });
  let data = await resp.json()
  if (!resp.ok)
    throw new Error(data.message)
  return data;
}

export async function GetUsers(token, is_admin) {
  let url_path = is_admin ? "/admin_users" : "/users"
  const resp = await fetch(
    ApiConfig.endpoint + url_path, {
    method: "GET",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  let data = await resp.json()
  if (!resp.ok)
    throw new Error(data.message)
  return data;
}

export async function SetUsers(token, users, is_admin) {
  let url_path = is_admin ? "/admin_users" : "/users"
  const resp = await fetch(
    ApiConfig.endpoint + url_path, {
    method: "PUT",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(users),
  });
  let data = await resp.json()
  if (!resp.ok)
    throw new Error(data.message)
  return data;
}
